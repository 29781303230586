<template>
    <div>
        <!-- Blog Start -->
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <!-- Blog list Start -->
                    <div class="col-lg-12">
                        <div class="row g-5" v-if="tableData.length > 0">
                            <div class="col-md-4 wow slideInUp" data-wow-delay="0.1s" v-for="item in tableData" :key="item._id">
                                <div class="blog-item bg-light rounded overflow-hidden">
                                    <div class="blog-img position-relative overflow-hidden">
                                        <iframe width="340" height="240" :src="item.link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div class="p-4">
                                        <h4 class="mb-3">{{ item.title }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            No data found
                        </div>
                    </div>
                    <!-- Blog list End -->
                </div>
            </div>
        </div>
        <!-- Blog End -->

    </div>
</template>
<script>
// @ is an alias to /src
import HomeDataService from "../services/HomeDataService";

export default {
  name: 'Videos',
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
    videoList() {
        HomeDataService.videoList()
        .then((response) => {
          this.tableData = response.data.data; 
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.videoList();
  },
}
</script>
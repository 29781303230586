import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PowerCleaner from '../views/PowerCleaner.vue'
import OwnerApp from '../views/OwnerApp.vue'
import Videos from '../views/Videos.vue'
import Faq from '../views/Faq.vue'
import Contact from '../views/Contact.vue'
import PackageInfo from '../views/PackageInfo.vue'
import PaymentPage from '../views/PaymentPage.vue'
import PaymentSuccess from '../views/PaymentSuccess'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home - Package Disabler' }
  },
  {
    path: '/power-cleaner',
    name: 'PowerCleaner',
    component: PowerCleaner,
    meta: { title: 'Power Cleaner - Package Disabler' }
  },
  {
    path: '/package-info',
    name: 'PackageInfo',
    component: PackageInfo,
    meta: { title: 'Package Info - Package Disabler' }
  },
  {
    path: '/owner-app',
    name: 'OwnerApp',
    component: OwnerApp,
    meta: { title: 'Owner App - Package Disabler' }
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    meta: { title: 'Videos - Package Disabler' }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: { title: 'FAQ - Package Disabler' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact Us - Package Disabler' }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentPage,
    meta: { title: 'Payment - Package Disabler' }
  },
  {
    path: '/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})
router.afterEach((to, from) => {
  document.title = to.meta.title;
});
export default router

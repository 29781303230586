<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <!--<header class="pricing-table-header">
        <div class="container">
          <h3 class="text-center pricing-table-title">Pricing Plan</h3>
            <div class="list-group list-group-horizontal pricing-plans-tab" role="tablist">
                <a class="list-group-item list-group-item-action active" id="monthly-plans-tab" data-toggle="list" href="#monthly-plans" role="tab" aria-controls="monthly-plans" aria-selected="true">Subscription</a>
                <a class="list-group-item list-group-item-action" id="annual-plans-tab" data-toggle="list" href="#annual-plans" role="tab" aria-controls="annual-plans" aria-selected="false">No Subscription</a>
            </div>
        </div>
    </header>-->
    <div class="container" data-wow-delay="0.1s">
        <div class="row" v-if="customPriceList">
                <h3 class="text-center pricing-table-title">Pricing Plan</h3>
                <div class="col-md-4" v-for="price in customPriceList" :key="price.id">
                    <div class="card pricing-card text-center shadow border-0">
                        <div class="card-header">
                            <h5 class="pricing-plan-title">{{ price.productName }}</h5>
                        </div>
                        <div class="card-body">
                            <ul class="pricing-plan-features">
                                <li v-for="(feature, i) in price.featureList" :key="i">{{ feature }} 
                                    <i class="fa fa-check text-primary pt-1 feature-checklist-icon"></i>
                                </li>
                            </ul>
                            <div :style="{ display: 'flex', 'justify-content': 'center' }">
                                <a v-if="price.subscriptionPrice" :href="'/payment?id='+price.subscriptionPrice._id" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(price.subscriptionPrice.amount) }})</a>
                                <a v-if="price.oneTimePrice" :href="'/payment?id='+price.oneTimePrice._id" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(price.oneTimePrice.amount) }})</a>
                                <a v-if="price.redirectUrl" :href="price.redirectUrl" class="btn btn-primary pricing-plan-purchase-btn">Try Now</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <!--<div class="container">
      <div class="tab-content pricing-tab-content" id="pills-tabContent">
          <div class="tab-pane show active" id="monthly-plans" role="tabpanel" aria-labelledby="monthly-plans-tab">
            <div class="row" v-if="recurringPriceList">
                    <div class="col-md-4" v-for="price in recurringPriceList" :key="price.id">
                        <div class="card pricing-card text-center shadow border-0">
                            <div class="card-header">
                                <h5 class="pricing-plan-title">{{ price.name }}</h5>
                                <h3 class="pricing-plan-title">{{ formatCurrency(price.amount) }} / {{ price.interval }}</h3>
                            </div>
                            <div class="card-body">
                                <ul class="pricing-plan-features">
                                    <li v-for="(feature, i) in price.features" :key="i">{{ feature.text }} 
                                        <i v-if="feature.checked == true" class="fa fa-check text-primary pt-1 feature-checklist-icon"></i>
                                        <i v-else class="fa fa-times text-danger pt-1 feature-checklist-icon"></i>
                                    </li>
                                </ul>
                                <div :style="{ display: 'flex', 'justify-content': 'center' }">
                                    <a :href="'/payment?id='+price._id" target="_blank" class="btn btn-success pricing-plan-purchase-btn">Subscription ({{ formatCurrency(5) }})</a>&nbsp;
                                    <a :href="'/payment?id='+price._id" target="_blank" class="btn btn-primary pricing-plan-purchase-btn">Onetime ({{ formatCurrency(5) }})</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
          </div>
          <div class="tab-pane" id="annual-plans" role="tabpanel" aria-labelledby="annual-plans-tab">
          <div class="row" v-if="oneTimePriceList">
            <div class="col-md-4" v-for="price in oneTimePriceList" :key="price.id">
                <div class="card pricing-card text-center shadow border-0">
                    <div class="card-header">
                        <h5 class="pricing-plan-title">{{ price.name }}</h5>
                        <h3 class="pricing-plan-title">{{ formatCurrency(price.amount) }}</h3>
                    </div>
                    <div class="card-body">
                        <ul class="pricing-plan-features">
                            <li v-for="(feature, i) in price.features" :key="i">{{ feature.text }} 
                                <i v-if="feature.checked == true" class="fa fa-check text-primary pt-1 feature-checklist-icon"></i>
                                <i v-else class="fa fa-times text-danger pt-1 feature-checklist-icon"></i>
                            </li>
                        </ul>
                        <a :href="'/payment?id='+price._id" target="_blank" class="btn btn-success pricing-plan-purchase-btn">Choose Plan</a>
                    </div>
                </div>
            </div>
          </div>
          </div>
      </div>
    </div>-->

    <!-- About Start -->
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-7">
                    <div class="section-title position-relative pb-3 mb-5">
                        <h5 class="fw-bold text-primary text-uppercase">About Package Disabler</h5>
                    </div>
                    <div class="row g-0 mb-3">
                        <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>The only NON-root solution that let’s you disable any unwanted packages that come pre-installed / installed with your phone / tablet.</h5>
                        <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>You can disable apps with 1 click. Disabled application / package will not be uninstalled or updated using Google play or any other means.</h5>
                        <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>This app also provides password / uninstall protection to avoid misuse.</h5>
                    </div>
                    <div class="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style="width: 60px; height: 60px;">
                            <i class="fa fa-phone-alt text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Call to ask any question</h5>
                            <h4 class="text-primary mb-0">+442031294012</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5" style="min-height: 500px;">
                    <div class="position-relative h-100">
                        <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="https://www.packagedisabler.com/images/ezgif.com-webp-to-png-removebg.png" style="object-fit: cover;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->

    <!-- Service Start -->
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
                <h5 class="fw-bold text-primary text-uppercase">Key Features</h5>
            </div>
            <div class="row g-5">
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-mobile text-white"></i>
                        </div>
                        <h4 class="mb-3">Apps Management</h4>
                        <p class="m-0">Disabling and enabling any packages or apps unlimited.</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-bug text-white"></i>
                        </div>
                        <h4 class="mb-3">Bloatware Remova</h4>
                        <p class="m-0">One click bloatware removal.</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-chart-pie text-white"></i>
                        </div>
                        <h4 class="mb-3">Reports</h4>
                        <p class="m-0">Export your disabled list to the external storage to import them later. & Batch operation to enable all disabled packages</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-check text-white"></i>
                        </div>
                        <h4 class="mb-3">Batch operation</h4>
                        <p class="m-0">Batch operation to enable all disabled packages</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-search text-white"></i>
                        </div>
                        <h4 class="mb-3">Search & Filters</h4>
                        <p class="m-0">Quickly find apps using the search functionality, Filter to show all disabled packages, Filter to show all installed apps, Filter to show all system packages</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="service-icon">
                            <i class="fa fa-lock text-white"></i>
                        </div>
                        <h4 class="mb-3">Security</h4>
                        <p class="m-0">Uninstall / password protection</p>
                        <a class="btn btn-lg btn-primary rounded" href="">
                            <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->

    <!-- Quote Start -->
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-7">
                    <div class="section-title position-relative pb-3 mb-5">
                        <h5 class="fw-bold text-primary text-uppercase">Request A Quote</h5>
                        <h1 class="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
                    </div>
                    <div class="row gx-3">
                        <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                        </div>
                        <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                            <h5 class="mb-4"><i class="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support</h5>
                        </div>
                    </div>                    
                    <div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style="width: 60px; height: 60px;">
                            <i class="fa fa-phone-alt text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Call to ask any question</h5>
                            <h4 class="text-primary mb-0">+442031294012</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                        <form ref="contactUsForm">
                            <div class="row g-3">
                                <div class="col-xl-12">
                                    <input type="text" v-model="name" class="form-control bg-light border-0" placeholder="Your Name" style="height: 55px;">
                                </div>
                                <div class="col-12">
                                    <input type="email" v-model="email" class="form-control bg-light border-0" placeholder="Your Email" style="height: 55px;">
                                </div>
                                <div class="col-12">
                                    <input type="text" v-model="subject" class="form-control bg-light border-0" placeholder="Subject" style="height: 55px;">
                                </div>
                                <div class="col-12">
                                    <textarea v-model="message" class="form-control bg-light border-0" rows="3" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-dark w-100 py-3" :disabled="isDisabled" @click="contactUs()" type="button">Request A Quote</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Quote End -->
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import PaymentService from "../services/PaymentService";
import HomeDataService from "../services/HomeDataService";
import {useToast} from 'vue-toast-notification';
const $toast = useToast();
export default {
  name: 'HomeView',
  components: {
    HelloWorld,
  },
  data() {
    return {
      isDisabled: false,
      recurringPriceList: [],
      oneTimePriceList: [],
      customPriceList: [],
      formatter: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2
        }),
        email:"",
        name:"",
        subject:"",
        message:"",

    };
  },
  methods: {
    priceList() {
        PaymentService.priceList()
        .then((response) => {
          if (response.data.data) { 
            this.recurringPriceList = response.data.data.recurringPriceList; 
            this.oneTimePriceList = response.data.data.oneTimePriceList;
            this.customPriceList = response.data.data.customPriceList;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    contactUs() {
        this.isDisabled = true;
        let data =  { email: this.email, name: this.name, subject: this.subject, message: this.message }
        HomeDataService.contactUs(data)
        .then((response) => {
            this.$refs.contactUsForm.reset();
            this.email="";this.name="",this.subject="";this.message="";
            this.isDisabled = false;
            $toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            $toast.error(e.response.data.respones_msg);
        });
    },
    formatCurrency(amount) {
        let formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2
        })
        return formatter.format(amount);
    }
  },
  beforeMount() {
    this.priceList();
  },
}
</script>

import http from "../http-common";

class HomeDataService {
    videoList() {
        return http.get("video/list");
    }
    subscribeNewsletter(data) {
        return http.post("newsletter/create", data);
    }
    contactUs(data) {
        return http.post("contactus/create", data);
    }
    faqList(data) {
        return http.get("faq/list", { params: data });
    }
}

export default new HomeDataService();
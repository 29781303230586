<template>
    <div class="container-fluid">
        <div class="sticky-container">
            <ul class="sticky">
                <li>
                    <img :src="whatsappLogo" width="50" height="50">
                    <p><a href="https://api.whatsapp.com/send/?phone=447449294718&text=Please contact us on WhatsApp for a faster reply." target="_blank">Contact us <br>on Whatsapp</a></p>
                </li>
            </ul>
        </div>
        <div class="container-fluid1">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-5 block-1">
                    <div>
                        <h4><b>{{ planDetail.name }}</b></h4><hr>
                        <p class="dis my-3 info fst-normal color-white">{{ planDetail.description }}</p>
                        <h4 class="">Summary</h4><hr>
                        <ul :style=" { 'list-style-type': 'none' }">
                            <li><span class="color-white">Plan Price</span> <span class="float-right color-black"><b> {{ formatCurrency(planDetail.amount) }} <span v-if="planDetail.type == 'recurring'"> / {{ planDetail.interval }}</span></b></span> </li>
                            <li><span class="color-white">Product</span> <span class="float-right color-black"><b>{{ planDetail.name }}</b></span> </li>
                            <li><span class="color-white">Payment Type</span> <span class="float-right color-black"><b>{{ planDetail.type }}</b></span> </li>
                            <li><span class="color-white">Qty</span> <span class="float-right color-black"><b>1</b></span> </li>
                            <li><span class="color-white">Discount</span> <span class="float-right color-black"><b>{{ formatCurrency(discount) }}</b></span> </li>
                        </ul>
                        <hr>
                        <p>
                            <span class=""><b>Total</b></span> 
                            <span class="float-right color-black">
                                <b>{{ formatCurrency(total - discount) }}</b>
                            </span>
                        </p>
                        <!--<div class="mb-3 text-center">
                            <button type="button" class="btn btn-dark">Switch to onetime</button>
                        </div>-->
                    </div>
                </div>
                <div class="col-md-5 block-2">
                    <form v-if="!paymentSuccess">
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Email<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <input type="email" :class="errors.email ? 'input-invalid' : ''" class="form-control input-sm" v-model="email" @change="validateEmail()">
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Password</label>
                            <div class="col-sm-10">
                                <input type="password" v-model="password" :class="errors.password ? 'input-invalid' : ''" class="form-control input-sm" :disabled="passwordDisabled" @change="validatePassword()">
                                <small class="text-danger"><b>{{ passwordHelpText }}</b></small>
                            </div>
                        </div>
                        <div class="form-group row mb-2" v-if="!updateSubscription">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Phone</label>
                            <div class="col-sm-10">
                                <vue-tel-input v-model="phone" :defaultCountry="44" mode="international"
                                :dropdownOptions="{ showDialCodeInSelection: true, showSearchBox: true, showFlags: true }">
                                    <template v-slot:arrow-icon>
                                        <span v-if="open"><i class="fa fa-up"></i> </span>
                                        <span v-else><i class="fa fa-arrow-down"></i> </span>
                                    </template>
                                </vue-tel-input>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Qty<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <input type="number" v-model="qty" :class="errors.qty ? 'input-invalid' : ''" class="form-control input-sm" min="1" @change="updateAmount()">
                            </div>
                        </div>
                        <div class="form-group row mb-2" v-if="!updateSubscription">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Coupon</label>
                            <div class="col-sm-10">
                                <div class="input-group w-auto">
                                    <input type="text" v-model="coupon" class="form-control input-sm" placeholder="Enter Coupon Code" />
                                    <button type="button" :disabled="isDisabled" class="btn btn-dark input-sm" data-mdb-ripple-color="dark" @click="applyCoupon()">
                                        Apply
                                    </button>
                                </div>
                                <output class="badge badge-success" v-if="discount > 0">{{ coupon }}</output>
                            </div>
                        </div>
                        <div class="form-group row mb-2" v-if="!updateSubscription">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Card<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <div ref="card"></div>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Captcha</label>
                            <div class="col-sm-10">
                                <vue-recaptcha ref="recaptcha" 
                                    :sitekey="siteKey"
                                    @verify="verifyCaptchaMethod"
                                    >
                                </vue-recaptcha>
                            </div>
                        </div>
                        <div class="mb-3 text-center">
                            <button type="button" class="btn btn-success" :disabled="isDisabled" @click="makePayment()">Pay {{ formatCurrency(total - discount) }} <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                            <!--<button type="button" class="btn btn-light" @click="$router.push({ path: '/'})" :style="{'float':'right'}">Back</button>-->
                        </div>
                    </form>
                    
                    <div v-if="paymentSuccess">
                        <h3 class="text-center">Thank you for your payment</h3>
                        <!--<p class="text-center">Your order # is: 100000023</p>-->
                        <p class="text-center">You will receive an order confirmation email with details of your subscription.</p>
                        <div class="mb-3 text-center">
                            <a :href="loginUrl"><button type="button" class="btn btn-success">Login Page</button></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import PaymentService from "../services/PaymentService";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
const $toast = useToast();

const appearance = {
  theme: "night",
};
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(appearance),
  card = undefined;

export default {
  components: { 
    VueRecaptcha,
    VueTelInput
  },
  props: {
    logo: {
      type: String,
      default: "./img/logo-light.png",
    },
    whatsappLogo: {
      type: String,
      default: "./img/whatsapp-50.png",
    },
  },
  data() {
    return {
        isDisabled: false,
        passwordDisabled: true,
        priceId: this.$route.query.id,
        planDetail: "",
        total: 0,
        email: "",
        password: "",
        qty: 1,
        coupon: "",
        discount: 0,
        errors: {
            email: false,
            password: false,
            qty: false,
        },
        paymentSuccess: false,
        updateSubscription: false,
        siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
        captcha: "",
        phone:"",
        loginUrl: process.env.VUE_APP_APP_URL,
        passwordHelpText:"Password only required for new account.",
    }
  },
  methods: {

    validateEmail() {
        let data = { email: this.email, priceId: this.priceId };
        PaymentService.validateEmail(data)
        .then((response) => {
            if (response.data.data.passwordRequired == true) {
                this.passwordDisabled = false;
                this.errors.password = true; 
            } else {
                this.passwordDisabled = true;
            }
            this.passwordHelpText = "Password only required for new account."
            this.updateSubscription = response.data.data.updateSubscription;
            this.$nextTick(function () {
                if (!card) {
                    card = elements.create("card");
                    card.mount(this.$refs.card);
                } else {
                    card.mount(this.$refs.card);
                }
            })
            this.errors.email = false;
        }) .catch((e) => {
            this.errors.email = true;
            this.updateSubscription = e.response.data.data.updateSubscription;
            if (!this.updateSubscription) {
                this.$nextTick(function () {
                    if (!card) {
                        card = elements.create("card");
                        card.mount(this.$refs.card);
                    } else {
                        card.mount(this.$refs.card);
                    }
                })
            } else {
                this.passwordDisabled = false;
                this.passwordHelpText = "Account exist, enter the password and verify it to update your subscription."
            }
            $toast.error(e.response.data.respones_msg);
        });
    },

    validatePassword() {
        this.errors.password = false; 
    },
    makePayment() {
        if (!this.captcha) {
            $toast.error("Please verify captcha.");
            return;  
        }
        let context = this;
        context.isDisabled = true;
        if (this.updateSubscription) {
            let data = {
                qty: context.qty,
                email: context.email,
                password: context.password,
                priceId: context.priceId,
                captcha: context.captcha,
            };
            PaymentService.updateSubscription(data)
            .then((response) => {
                stripe
                .confirmCardPayment(response.data.data)
                .then(function (result) {
                    if (result.error) {
                        context.$refs.recaptcha.reset();
                        context.isDisabled = false;
                        $toast.error(result.error.message);
                    } else {
                        context.isDisabled = false;
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            $toast.success(response.data.respones_msg);
                            context.paymentSuccess = true;
                        }
                    }
                });
            }).catch((e) => {
                context.$refs.recaptcha.reset();
                $toast.error(e.response.data.respones_msg);
                context.isDisabled = false;
            });
            return;
        }
        
        if (!this.email) {
            this.errors.email = true; 
        }
        if (!this.qty) {
            this.errors.qty = true; 
        }

        stripe.createToken(card).then(function (result) {
            if (result.error) {
                self.hasCardErrors = true;
                context.isDisabled = false;
                //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                $toast.error(result.error.message);
                this.$refs.recaptcha.reset();
                return;
            }
            let token = result.token;
            stripe.createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                name: context.email,
                email:context.email,
                },
            })
            .then(function (result) {
                let data = {
                    token: token,
                    qty: context.qty,
                    couponCode: context.coupon,
                    password: context.password,
                    email: context.email,
                    priceId: context.priceId,
                    payment_method_id: result.paymentMethod.id,
                    captcha: context.captcha,
                    phone_number: context.phone,
                };

                PaymentService.makePayment(data)
                .then((response) => {
                    stripe
                    .confirmCardPayment(response.data.data, {
                        payment_method: {
                        card: card,
                        billing_details: {
                            name: context.email,
                        },
                        },
                    })
                    .then(function (result) {
                        if (result.error) {
                            context.$refs.recaptcha.reset();
                            context.isDisabled = false;
                            $toast.error(result.error.message);
                        } else {
                            context.isDisabled = false;
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                //console.log(result);
                                $toast.success(response.data.respones_msg);
                                card.clear();
                                context.paymentSuccess = true;
                            }
                        }
                    });
                })
                .catch((e) => {
                    context.$refs.recaptcha.reset();
                    context.isDisabled = false;
                    if (e.response) {
                        $toast.error(e.response.data.respones_msg);
                    } else {
                        $toast.error(e);
                    }
                });
            });
      });
    },

    priceDetail() {
        let data = { id: this.priceId }
        PaymentService.priceDetail(data)
        .then((response) => {
            this.planDetail = response.data.data;
            this.total = this.planDetail.amount
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    updateAmount() {
        if (this.qty <= 0) {
            this.qty = 1;
        }
        this.total = this.planDetail.amount * this.qty;
    },

    applyCoupon() {
        this.discount = 0;
        this.isDisabled = true;
        let data = { couponCode: this.coupon, priceId: this.priceId, qty: this.qty }
        PaymentService.applyCoupon(data)
        .then((response) => {
            this.isDisabled = false;
            this.discount = response.data.data;
            $toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            $toast.error(e.response.data.respones_msg);
        });
    },

    formatCurrency(amount) {
        let formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2
        })
        return formatter.format(amount);
    },

    verifyCaptchaMethod(recaptchaToken) {
      this.captcha = recaptchaToken;
    }
  },
  mounted: function () {
    if (!card) {
        card = elements.create("card");
        card.mount(this.$refs.card);
    } else {
        card.mount(this.$refs.card);
    }
  },
  beforeMount() {
    this.priceDetail();
  },
}
</script>

<style>

@media screen and (min-width: 600px) {
    .set-bg {
        padding: 5px;
        background-color: #f6f7f8 /* #FFCCBC */;
    }
    .container-fluid1 {
        margin-top: 30px;
        padding: 5px;
    }
}

.block-1 {
    background-color: #527eee;
    padding: 20px;
}
.block-2 {
    background-color: var(--md-theme-default-background,#fff);
    padding: 20px;
}
.float-right{
  float: right;
}
.color-white{
  color: var(--md-theme-default-background, #fff);
}
.color-black{
  color: black;
}
.input-sm{
    height: 35px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*stripe css end*/

.input-invalid {
    border-color: #fa755a;
}


element.style {
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.required {
    content:"*";
    color:red;
}
</style>
<template>
    <div>
        <!-- About Start -->
        <div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="section-title position-relative pb-3 mb-5">
                            <h5 class="fw-bold text-primary text-uppercase">About Power Cleaner - The must have for Samsung Devices.</h5>
                        </div>
                        <p>Power cleaner is a utility app that will ensure that your device is clean of unwanted files, apps, cookies etc. It can be scheduled so that cleaning can be automated.</p>
                        <div class="row g-0 mb-3">
                            <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Modular wipe:</b> User can select exactly what they want to clean and clean it with just one click</h5>
                            <h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i><b>Selective wipe:</b> User can select a data from WHICH the files and apps needs to be cleaned, also select WHAT items needs to be cleaned and WHEN to clean them.</h5>
                        </div>
                        <div class="iframe-container">
                            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/AIN7KhfvVtE" title="Power Cleaner (How to use power cleaner?)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-lg-5" style="min-height: 500px;">
                        <div class="position-relative h-100">
                            <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="https://www.packagedisabler.com/images/power-cleaner.png" style="object-fit: cover;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->
        
        <!-- Service Start -->
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
                    <h5 class="fw-bold text-primary text-uppercase">Key Features</h5>
                </div>
                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-mobile text-white"></i>
                            </div>
                            <h4 class="mb-3">Silent Application Uninstall</h4>
                            <p class="m-0">Applications can be uninstalled based on date range. User interaction is not needed</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-bug text-white"></i>
                            </div>
                            <h4 class="mb-3">Clear APP data</h4>
                            <p class="m-0">Application data can be cleaned to free up space. Date based filtering is possible</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-chart-pie text-white"></i>
                            </div>
                            <h4 class="mb-3">Clear Call Logs</h4>
                            <p class="m-0">Call history can be wiped based on date filter</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-check text-white"></i>
                            </div>
                            <h4 class="mb-3">Clean browse history and cookies</h4>
                            <p class="m-0">All browser history and cookies from Chrome, Firefox, etc will be cleaned</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-search text-white"></i>
                            </div>
                            <h4 class="mb-3">Files</h4>
                            <p class="m-0">Files can be filtered based on date and type and be cleaned</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div class="service-icon">
                                <i class="fa fa-lock text-white"></i>
                            </div>
                            <h4 class="mb-3">Trigger function</h4>
                            <p class="m-0">Wipe rules can be specified and wipe operation will run on REBOOT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </div>
</template>